import { toSafeInteger, isArray, isEmpty, isObject, capitalize, toLower } from "lodash";

export const validateEmail = (email) => {
	//eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export default {
	data() {
		return {
			formLoading: false,
			formValid: true,
			formReadOnly: true,
			formErrors: [],
			validateRules: {
				required(val, field, isInteger) {
					if (isArray(val) && isEmpty(val)) {
						return `${field} is required`;
					}
					if (isObject(val) && isEmpty(val)) {
						return `${field} is required`;
					}
					if (isInteger && parseInt(val) <= 0) {
						return `${field} is required.`;
					}
					return !!val || `${field} is required`;
				},
				invalidNumber(val, field) {
					if (val) {
						return `${field} is invalid`;
					}
				},
				file_required(val, field) {
					return !!val || `${field} is required`;
				},
				validateQty(val, maxQty, field) {
					let newQty = toSafeInteger(val);
					let newAvailable = toSafeInteger(maxQty);
					if (newQty > newAvailable) {
						return `${field} is must be less than or equal to ${newAvailable}.`;
					}
					return true;
				},
				validEmail(val, field) {
					if (val) {
						if (validateEmail(val)) {
							return true;
						}
						return `${field} must be valid`;
					}
					return true;
				},
				validFloat(val, field) {
					if (val) {
						if (/^\d*\.?\d*$/.test(val)) {
							return true;
						}
						return `${field} must be valid`;
					}
					return true;
				},
				validNumeric(val, field) {
					if (val) {
						if (/^\d+$/.test(val)) {
							return true;
						}
						return `${field} must be valid (only digits) & greater than 0`;
					}
					return true;
				},
				maxLength(val, field, maxLen) {
					if (val) {
						val = val.toString();
						if (val.length <= maxLen) {
							return true;
						}
						return `${field} length must be less than or equal to ${maxLen}`;
					}
					return true;
				},
				minLength(val, field, minLen) {
					if (val) {
						val = val.toString();
						if (val.length >= minLen) {
							return true;
						}
						return `${field} length must be greater than or equal to ${minLen}`;
					}
					return true;
				},
				lessThan(val, field, len) {
					if (val) {
						val = parseInt(val);
						if (val <= len) {
							return true;
						}
						return `${field} must be less than or equal to ${len}`;
					}
					return true;
				},
				greaterThan(val, field, minLen) {
					if (val) {
						val = val.toString();
						if (val.length >= minLen) {
							return true;
						}
						return `${field} length must be greater than or equal to ${minLen}`;
					}
					return true;
				},
				deleteValidation(val, field, barcode) {
					if (val) {
						val = val.toString();
						barcode = barcode.toString();
						if (val === barcode) {
							return true;
						}
						return `${field} must be equal to ${barcode}`;
					}
					return true;
				},
				confirmPassword(val, field, old) {
					if (val) {
						val = val.toString();
						old = old.toString();
						if (val === old) {
							return true;
						}
						return `${field} you entered don't match to new password`;
					}
					return true;
				},
			},
		};
	},
	methods: {
		validateForm(formRef) {
			const formErrors = [];
			const { inputs } = formRef;
			for (let i = 0; i < inputs.length; i++) {
				const { errorBucket } = inputs[i];
				for (let z = 0; z < errorBucket.length; z++) {
					const errorMessage = capitalize(toLower(errorBucket[z]));
					formErrors.push(errorMessage);
				}
			}
			return formErrors;
		},
	},
};

<template>
	<Dialog :dialog="mailDialog" :dialog-width="dialogWidth">
		<template v-slot:title>{{ title }}</template>
		<template v-slot:body>
			<v-container class="px-10" fluid>
				<v-form
					ref="mail_form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="submit()"
				>
					<v-layout class="mb-4">
						<v-flex md3 class="my-auto">
							<label class="font-weight-500 font-size-16 required">Recipients</label>
						</v-flex>
						<v-flex md9>
							<v-combobox
								ref="recipientsCombobox"
								class="border-dark-grey border"
								chips
								:items="autocompleteEmails()"
								v-model="email.recipients"
								placeholder="Recipients"
								label="Recipients"
								multiple
								flat
								:disabled="formLoading"
								v-on:change="validateTagEmail(email.recipients)"
								hide-details
								solo
								:rules="[validateRules.required(email.recipients, 'Recipients')]"
							>
								<template v-slot:append-outer>
									<v-chip
										v-on:click="emailCCShow = !emailCCShow"
										class="append-outer-chip"
										label
										:disabled="formLoading"
										:color="emailCCShow ? 'green' : ''"
										:text-color="emailCCShow ? 'white' : ''"
									>
										<span class="font-weight-500 font-size-16">CC</span>
									</v-chip>
								</template>
								<template v-slot:selection="{ attrs, item, select, selected }">
									<v-chip
										label
										v-bind="attrs"
										:input-value="selected"
										close
										@click:close="removeEmail(item, 'recipients')"
									>
										<span class="font-weight-500 font-size-16">{{ item }}</span>
									</v-chip>
								</template>
							</v-combobox>
						</v-flex>
					</v-layout>
					<v-layout v-if="emailCCShow" class="my-4">
						<v-flex md3 class="my-auto">
							<label class="font-weight-500 font-size-16">CC</label>
						</v-flex>
						<v-flex md9>
							<v-combobox
								class="border-dark-grey"
								v-model="email.cc"
								chips
								:disabled="formLoading"
								:items="autocompleteEmails()"
								placeholder="CC"
								label="CC"
								multiple
								flat
								v-on:change="validateTagEmail(email.cc)"
								hide-details
								solo
							>
								<template v-slot:selection="{ attrs, item, select, selected }">
									<v-chip
										label
										v-bind="attrs"
										:input-value="selected"
										close
										@click:close="removeEmail(item, 'cc')"
									>
										<span class="font-weight-500 font-size-16">{{ item }}</span>
									</v-chip>
								</template>
							</v-combobox>
						</v-flex>
					</v-layout>
					<v-layout class="my-4">
						<v-flex md3 class="my-auto">
							<label class="font-weight-500 font-size-16 required">Subject</label>
						</v-flex>
						<v-flex md9>
							<TextInput
								ref="subjectfield"
								dense
								v-model="email.subject"
								hide-details
								placeholder="Enter subject"
								:disabled="formLoading"
								:loading="formLoading"
								class="mt-0"
								:rules="[validateRules.required(email.subject, 'Subject')]"
							></TextInput>
						</v-flex>
					</v-layout>
					<v-layout class="my-4">
						<v-flex md12>
							<TinyMCE :disabled="formLoading" v-model="email.message"></TinyMCE>
						</v-flex>
					</v-layout>
				</v-form>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				tile
				v-on:click="close_dialog()"
				class="mx-2 custom-grey-border custom-bold-button"
			>
				Close
			</v-btn>

			<v-btn
				depressed
				tile
				v-on:click="submit()"
				class="mx-2 custom-bold-button white--text"
				color="blue darken-4"
			>
				<v-icon left>mdi-send mdi-rotate-315</v-icon>Send
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
// import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/components/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { validateEmail } from "@/core/plugins/validation-mixin";
import { GET, POST } from "@/core/services/store/request.module";
import { toSafeInteger, findIndex, map } from "lodash";
import TextInput from "@/view/components/TextInput.vue";

export default {
	mixins: [ValidationMixin],
	props: {
		mailDialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			formLoading: false,
			emailCCShow: false,
			email: {
				recipients: [],
				cc: [],
				subject: null,
				message: null,
			},
		};
	},
	watch: {
		mailDialog(param) {
			if (param) {
				this.init();
			}
		},
	},
	methods: {
		validateRecipients() {
			if (!this.email.recipients || this.email.recipients.length === 0) {
				this.$refs.recipientsCombobox.$el.classList.add("error-border");
				return false;
			} else if (!this.email.subject || this.email.subject.length === 0) {
				this.$refs.subjectfield.$el.classList.add("error-border");
				return false;
			} else {
				this.$refs.recipientsCombobox.$el.classList.remove("error-border");
				this.$refs.subjectfield.$el.classList.add("error-border");
				return true;
			}
		},
		close_dialog() {
			this.$emit("close", true);
			this.email = {
				recipients: [],
				cc: [],
				subject: null,
				message: null,
			};
		},
		init() {
			const _this = this;
			_this.formLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "/users/" + this.type + "/" + this.typeId + "/consent-template",
				})
				.then((response) => {
					console.log("data", response);

					this.email = new Object({
						recipients: response.person_emails,

						cc: [],
						subject: response.email_subject,
						message: response.email_body,
						action: "response",
					});
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
		submit() {
			if (!this.validateRecipients()) {
				return;
			}
			const _this = this;
			_this.formLoading = true;
			_this.$store
				.dispatch(POST, {
					url: "/users/" + _this.typeId + "/mail-sent",
					data: _this.email,
				})
				.then(() => {
					this.$emit("success", true);

					_this.close_dialog();
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
		validateTagEmail(emails) {
			for (let i = 0; i < emails.length; i++) {
				if (!validateEmail(emails[i])) {
					emails.splice(i, 1);
				}
			}
		},
		removeEmail(email, type) {
			const emails = this.email[type];
			const index = findIndex(emails, (row) => email == row);
			if (index >= 0) {
				this.email[type].splice(index, 1);
			}
		},
		autocompleteEmails() {
			return map(this.recipients, (row) => row.email);
		},
	},
	components: {
		Dialog,
		TinyMCE,
		TextInput,
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 70);
		},
	},
};
</script>

<style scoped>
.border-dark-grey {
	border: 1px solid rgb(151, 147, 147) !important;
}
.border-dark-grey > .v-select.v-select--chips .v-select__selections {
	border: 1px solid rgba(0, 0, 0, 0.87) !important;
}
.error-border {
	border: 1px solid red !important;
}
</style>

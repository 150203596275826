<template>
	<div class="btx-ck-editor">
		<ckeditor
			:editor="editor"
			:disabled="disabled"
			@input="onInput"
			:value="editorData"
			:config="editorConfig"
			v-html="editorData"
			@ready="editorReady = true"
		></ckeditor>
	</div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toString } from "lodash";

export default {
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			editorReady: true,
			editor: ClassicEditor,
			editorData: "",
			editorConfig: {
				toolbar: [
					"heading",
					"|",
					"bold",
					"italic",
					"|",
					"outdent",
					"indent",
					"|",
					"bulletedList",
					"numberedList",
					"blockQuote",
					"|",
					"undo",
					"redo",
				],
			},
		};
	},
	watch: {
		value() {
			this.init();
		},
	},
	methods: {
		onInput(value) {
			this.$emit("input", value);
		},
		init() {
			this.editorData = toString(this.value);
		},
	},
	mounted() {
		let _this = this;
		clearInterval(_this.timeInterval);
		_this.timeInterval = setInterval(function () {
			if (_this.editorReady) {
				this.editorData = toString(this.value);
				clearInterval(_this.timeInterval);
			}
		}, 500);
	},
	components: {
		ckeditor: CKEditor.component,
	},
};
</script>
